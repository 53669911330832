export type Breadcrumbs = {
  uri: string;
  name: string;
};

export const getSeoSchema = (
  breadcrumbs: Breadcrumbs[] | undefined,
  baseUrl: string,
  language: string
): string => {
  if (!breadcrumbs || !breadcrumbs.length) return '';

  const itemListElement = breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': `${baseUrl}${language}${breadcrumb.uri}`,
      name: breadcrumb.name,
    },
  }));

  const seoSchema = `{
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "BreadcrumbList",
        "itemListElement": ${JSON.stringify(itemListElement)}
      }
    ]
  }`;

  return seoSchema;
};
