import { RatePlanCodes } from '../../constants/rate-plan-codes';
import type { QueryParameters } from '../../constants';
import type { FormDataValues } from '@dx-ui/osc-shop-form';

export const mapRatesToSearchForm = (queryParamsObj: QueryParameters | undefined) => ({
  aarpRate: queryParamsObj?.token.includes(RatePlanCodes.AARP),
  corpAccount: queryParamsObj?.corporateCode,
  employeeRate: queryParamsObj?.employeeRate,
  friendsAndFamilyRate: queryParamsObj?.friendsAndFamilyRate,
  gvmtRates: queryParamsObj?.token.includes(RatePlanCodes.GOVERNMENT_MILITARY),
  groupCode: queryParamsObj?.groupCode,
  ownerVIPRate: queryParamsObj?.ownerVIPRate,
  promoCode: queryParamsObj?.promoCode,
  seniorRate: queryParamsObj?.token.includes(RatePlanCodes.SENIOR),
  travelAgents: queryParamsObj?.travelAgentRate,
  aaaRate: queryParamsObj?.token.includes(RatePlanCodes.AAA),
  redeemPts: queryParamsObj?.token.includes(RatePlanCodes.USE_POINTS) || queryParamsObj?.redeemPts,
  ownerHGVRate: queryParamsObj?.ownerHGVRate,
});

export const mapRatesToSearchUriBuilder = (
  queryParamsObj: QueryParameters | undefined,
  usePoints = false
) => ({
  aarp: queryParamsObj?.token.includes(RatePlanCodes.AARP),
  corpAccount: queryParamsObj?.corporateCode,
  employeeRate: queryParamsObj?.employeeRate,
  friendsAndFamilyRate: queryParamsObj?.friendsAndFamilyRate,
  government: queryParamsObj?.token.includes(RatePlanCodes.GOVERNMENT_MILITARY),
  groupCode: queryParamsObj?.groupCode,
  ownerVIPRate: queryParamsObj?.ownerVIPRate,
  promoCode: queryParamsObj?.promoCode,
  senior: queryParamsObj?.token.includes(RatePlanCodes.SENIOR),
  travelAgents: queryParamsObj?.travelAgentRate,
  tripleA: queryParamsObj?.token.includes(RatePlanCodes.AAA),
  usePoints:
    queryParamsObj?.token.includes(RatePlanCodes.USE_POINTS) ||
    queryParamsObj?.redeemPts ||
    usePoints,
  ownerHGVRate: queryParamsObj?.ownerHGVRate,
});

export const isNoRatesOrRedeemPtsSelected = (formData: FormDataValues) => {
  const specialRates = formData?.specialRates;
  //no special rate applied
  if (specialRates && Object.values(specialRates).filter(Boolean).length === 0) return true;
  //or only one special rate applied and that rate is usePoints
  else if (
    specialRates &&
    Object.values(specialRates).filter(Boolean).length < 2 &&
    specialRates.redeemPts
  )
    return true;
  return false;
};
